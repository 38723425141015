import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import {
  Box,
  Heading,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Button,
  Text,
  Td,
} from "@chakra-ui/react";
import { UserContext } from "../../contexts/User";

const HomeAdmin = () => {
  const navigate = useNavigate();

  const { listUsersApi } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1, lastPage: 1 });
  const handleSelectUser = (user, id) => {
    localStorage.setItem("userInfoID", id);
    navigate(`/user/${user}`);
  };

  const getUsers = async (page = 1) => {
    // Agora aceita uma página opcional
    try {
      const usersData = await listUsersApi(page);
      setUsers(usersData.data?.[0]);
      setPagination({
        currentPage: usersData.data.current_page, // Ajuste para refletir a página correta
        lastPage: usersData.data.last_page,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const recoveredUser = JSON.parse(localStorage.getItem("user"));
    console.log(recoveredUser);
    if (recoveredUser.role_id === 1) {
      getUsers();
    } else if (recoveredUser.role_id === 3) {
      navigate(`/home/${recoveredUser.name}`);
    }
  }, []);
  return (
    <>
      <Navbar />
      <Box
        p={8}
        ml={{ base: 0, md: 60 }}
        bgColor={"#f0f8ff"}
        minH={"100vh"}
        mt={"-35px"}
      >
        <Stack spacing={8}>
          <Box>
            <Box overflowX="auto">
              {Object.entries(users).map(([roleKey, roleData]) => {
                console.log([roleKey, roleData]);
                if (!roleData.data.length) return null; // Não renderiza se não houver usuários na role

                const roleNames = {
                  role_1: "Administradores",
                  role_2: "Professores",
                  role_3: "Alunos",
                };

                return (
                  <Box key={roleKey} mb={8}>
                    <Text fontSize="lg" fontWeight="bold" mb={4}>
                      {roleNames[roleKey]}
                    </Text>
                    <Table variant="striped" overflow={"auto"}>
                      <Thead>
                        <Tr>
                          <Th>ID</Th>
                          <Th>Nome</Th>
                          <Th>E-mail</Th>
                          {roleKey === "role_2" && (
                            <Th>Qtde. de Perguntas</Th>
                          )}{" "}
                          {/* Adiciona coluna extra para Professores */}
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {roleData.data.map((user) => (
                          <Tr key={user.id}>
                            <Td>{user.id}</Td>
                            <Td>{user.name}</Td>
                            <Td>{user.email}</Td>
                            {roleKey === "role_2" && (
                              <Td textAlign={"center"}>
                                {user.questions_count}
                              </Td>
                            )}{" "}
                            {/* Mostra questions_count apenas para Professores */}
                            <Td>
                              <Button
                                colorScheme="teal"
                                size={"sm"}
                                onClick={() =>
                                  handleSelectUser(user.name, user.id)
                                }
                              >
                                Ver perfil
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>

                    {/* Paginação */}
                    <Box display="flex" justifyContent="center" mt={4}>
                      <Button
                        size="sm"
                        colorScheme="gray"
                        onClick={() => getUsers(roleData.current_page - 1)}
                        isDisabled={roleData.current_page === 1}
                      >
                        Anterior
                      </Button>
                      <Text mx={3}>
                        Página {roleData.current_page} de {roleData.last_page}
                      </Text>
                      <Button
                        size="sm"
                        colorScheme="gray"
                        onClick={() => getUsers(roleData.current_page + 1)}
                        isDisabled={
                          roleData.current_page === roleData.last_page
                        }
                      >
                        Próxima
                      </Button>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default HomeAdmin;
