import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light", // Pode ser 'dark' para iniciar no modo escuro
  useSystemColorMode: false, // Se quiser seguir o tema do sistema, altere para true
};

const theme = extendTheme({ config });

export default theme;
