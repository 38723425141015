import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  useDisclosure,
  useToast,
  Text,
} from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import { UserContext } from "../../contexts/User";
import UserRegistrationModal from "../../Components/createUserModal";

const Users = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { listUsersApi, deleteUserApi } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1, lastPage: 1 });
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    getUsers();
  }, []);

  const handleSelectUser = (user, id) => {
    localStorage.setItem("userInfoID", id);
    navigate(`/user/${user}`);
  };

  const getUsers = async (page = 1) => {
    // Agora aceita uma página opcional
    try {
      const usersData = await listUsersApi(page);
      setUsers(usersData.data?.[0]);
      setPagination({
        currentPage: usersData.data.current_page, // Ajuste para refletir a página correta
        lastPage: usersData.data.last_page,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      const deleteUser = await deleteUserApi(userId);
      console.log(deleteUser.data);
      if (deleteUser.status === 200) {
        toast({
          title: "Usuário Removido",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getUsers();
  };

  return (
    <>
      <Navbar />
      <Box
        p={8}
        ml={{ base: 0, md: 60 }}
        bgColor={"#f0f8ff"}
        minH={"100vh"}
        mt={"-35px"}
      >
        <Flex justifyContent={"space-between"}>
          <Heading as="h1" size="xl" mb={4}></Heading>
          <Button colorScheme="teal" mb={4} onClick={onOpen}>
            Adicionar Usuário
          </Button>
        </Flex>
        <Box overflowX="auto">
          {Object.entries(users).map(([roleKey, roleData]) => {
            console.log([roleKey, roleData]);
            if (!roleData.data.length) return null; // Não renderiza se não houver usuários na role

            const roleNames = {
              role_1: "Administradores",
              role_2: "Professores",
              role_3: "Alunos",
            };

            return (
              <Box key={roleKey} mb={8}>
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                  {roleNames[roleKey]}
                </Text>
                <Table variant="striped" overflow={"auto"}>
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Nome</Th>
                      <Th>E-mail</Th>
                      {roleKey === "role_2" && <Th>Qtde. de Perguntas</Th>}{" "}
                      {/* Adiciona coluna extra para Professores */}
                      <Th>Ações</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {roleData.data.map((user) => (
                      <Tr key={user.id}>
                        <Td>{user.id}</Td>
                        <Td>{user.name}</Td>
                        <Td>{user.email}</Td>
                        {roleKey === "role_2" && (
                          <Td textAlign={"center"}>{user.questions_count}</Td>
                        )}{" "}
                        {/* Mostra questions_count apenas para Professores */}
                        <Td>
                          <Button
                            colorScheme="teal"
                            size={"sm"}
                            onClick={() => handleSelectUser(user.name, user.id)}
                          >
                            Ver perfil
                          </Button>
                          <Button
                            ml={5}
                            size={"sm"}
                            colorScheme="red"
                            onClick={() => deleteUser(user.id)}
                          >
                            Excluir
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>

                {/* Paginação */}
                <Box display="flex" justifyContent="center" mt={4}>
                  <Button
                    size="sm"
                    colorScheme="gray"
                    onClick={() => getUsers(roleData.current_page - 1)}
                    isDisabled={roleData.current_page === 1}
                  >
                    Anterior
                  </Button>
                  <Text mx={3}>
                    Página {roleData.current_page} de {roleData.last_page}
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="gray"
                    onClick={() => getUsers(roleData.current_page + 1)}
                    isDisabled={roleData.current_page === roleData.last_page}
                  >
                    Próxima
                  </Button>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>

      <UserRegistrationModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          getUsers();
        }}
      />
    </>
  );
};

export default Users;
