import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-mathtype";

function MeuEditor({ initialValue, onChange }) {
  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  const handleUploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);

      const response = await fetch(
        "https://api.colegionaval.com.br/public/api/upload-image",
        {
          method: "POST",
          // Remova o header "Content-Type" para o multipart funcionar corretamente
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Falha no upload da imagem");
      }

      const data = await response.json();
      return { default: data.url };
    } catch (error) {
      console.error("Erro no upload da imagem:", error);
      throw error;
    }
  };

  // Função para deletar uma imagem
  const handleDeleteImage = (imageSrc) => {
    const apiUrl = "https://api.colegionaval.com.br/public/api/delete-image";

    fetch(apiUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ src: imageSrc }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Imagem excluída com sucesso");
        } else {
          console.error("Erro ao excluir a imagem:", response.status);
        }
      })
      .catch((error) => {
        console.error("Erro ao excluir a imagem:", error);
      });
  };

  const API_URL = "https://api.colegionaval.com.br/public/api";
  const UPLOAD_ENDPOINT = "upload-image";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            let headers = new Headers();
            headers.append("Origin", "http://localhost:3000");
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "POST",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: res.url,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  // Define os itens da toolbar com base no role do usuário
  const toolbarItems = [
    "heading",
    "fontsize",
    "alignment",
    "fontColor",
    "fontBackgroundColor",
    "bold",
    "italic",
    "strikethrough",
    "underline",
    "subscript",
    "superscript",
    "|",
    "link",
    "outdent",
    "indent",
    "bulletedList",
    "numberedList",
    "todoList",
    "code",
    "codeBlock",
    "insertTable",
    "blockQuote",
    "imageUpload",
    "ImageToolbar",
    "mediaEmbed",
    "undo",
    "redo",
  ];

  // Se o usuário NÃO tiver role_id 3, adiciona MathType e ChemType
  if (user && user.role_id !== 3) {
    toolbarItems.push("MathType", "ChemType");
  }

  return (
    <CKEditor
      data={initialValue}
      editor={ClassicEditor}
      config={{
        extraPlugins: [uploadPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: toolbarItems,
        },
        image: {
          uploadMultiple: true,
        },
        // As configurações mathType e chemType podem permanecer, pois
        // se os botões não estiverem na toolbar, o usuário não os verá.
        mathType: {
          licenseKey: "YOUR_LICENSE_KEY",
        },
        chemType: {
          licenseKey: "YOUR_LICENSE_KEY",
        },
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        if (onChange) {
          onChange(data);
        }
      }}
    />
  );
}

export default MeuEditor;
