import React, { useContext, useState, useEffect, useRef } from "react";
import { MultiSelect } from "react-multi-select-component";
import {
  Box,
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Grid,
  VStack,
  useToast,
  useDisclosure,
  Input,
  Select,
  Flex,
  HStack,
  CheckboxGroup,
  Stack,
  Checkbox,
  Tag,
  TagLabel,
  TagCloseButton,
  ModalCloseButton,
  FormControl,
  FormLabel,
  SimpleGrid,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import Navbar from "../../Components/Navbar";
import { MateriaContext } from "../../contexts/Materia";
import MeuEditor from "../../Components/Editor/CKeditor";
const MateriaPage = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const roleId = user?.role_id;
  const toast = useToast();
  const {
    DetailMateriaApi,
    createRespostaQuestaoApi,
    deleteRespostaQuestoesApi,
    createQuestaoApi,
    deleteQuestionApi,
    updateQuestionApi,
    createSubjectApi,
    listSubjectsApi,
    deleteSubjectApi,
    getQuestionApi,
    updateAnswerApi,
    updateSubjectApi,
    getQuestionCountApi,
  } = useContext(MateriaContext);
  const [materia, setMateria] = useState();
  const [isQuestionEditing, setIsQuestionEditing] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState("");

  const {
    isOpen: isAnswerModalOpen,
    onOpen: onAnswerModalOpen,
    onClose: onAnswerModalClose,
  } = useDisclosure();
  const {
    isOpen: isQuestionModalOpen,
    onOpen: onQuestionModalOpen,
    onClose: onQuestionModalClose,
  } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onOpen: openEditModal,
    onClose: closeEditModal,
  } = useDisclosure();
  const [subjectToEdit, setSubjectToEdit] = useState(null);
  const [newSubjectText, setNewSubjectText] = useState("");
  const [newAnswer, setNewAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [newQuestion, setNewQuestion] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuestionBoxOpen, setIsQuestionBoxOpen] = useState(false);
  const [isSubjectBoxOpen, setIsSubjectBoxOpen] = useState(false);
  const [questionID, setQuestionID] = useState();
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState();
  const [subjectQuestion, setSubjectQuestion] = useState();
  const [updateSubject, setUpdateSubject] = useState();
  const [secondarySubjects, setSecondarySubjects] = useState([]);
  const [checkQuestion, setCheckQuestion] = useState();
  const [updateDifficulty, setUpdateDifficulty] = useState();
  const [editingAnswerId, setEditingAnswerId] = useState(null); // ID da resposta em edição
  const [answerUpdate, setAnswerUpdate] = useState("");
  const [answerVerifyUpdate, setAnswerVerifyUpdate] = useState(0);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [selectedAnswerId, setSelectedAnswerId] = useState(null);
  const [questionCount, setQuestionCount] = useState(0);
  const [answers, setAnswers] = useState([
    { answer: "", answer_verify: false },
  ]);

  const [subjectQuestionCheck, setsubjectQuestionCheck] = useState(null);
  const [selectedSecondarySubjects, setSelectedSecondarySubjects] = useState(
    []
  );
  const handleOpenEditModal = (subject) => {
    setSubjectToEdit(subject);
    setNewSubjectText(subject.subject);
    openEditModal();
  };
  const openModal = (id) => {
    fetchQuestionData(id);
    setIsModalOpen(true);
  };
  const fetchQuestionData = async (questionID) => {
    try {
      const response = await getQuestionApi(questionID);
      if (response.status === 200) {
        const questionData = response.data.question;
        setEditedQuestion(questionData?.question);
        setsubjectQuestionCheck(questionData?.subject_id);
        setUpdateSubject(questionData?.subject_id);
        setCheckQuestion(questionData);
      }
    } catch (error) {
      console.error("Erro ao buscar dados da questão:", error);
    }
  };

  const handleAddAnswerQuestion = () => {
    setAnswers([...answers, { answer: "", answer_verify: false }]);
  };

  const handleAnswerChange = (index, field, value) => {
    const updatedAnswers = [...answers];

    // Se o campo for "answer_verify" e a resposta for marcada como correta
    if (field === "answer_verify" && value === true) {
      // Desmarcar todas as outras respostas
      updatedAnswers.forEach((answer, i) => {
        if (i !== index) {
          answer.answer_verify = false;
        }
      });
    }

    updatedAnswers[index][field] = value;

    setAnswers(updatedAnswers);
  };

  const handleRemoveAnswer = (index) => {
    setAnswers(answers.filter((_, i) => i !== index));
  };
  const timeoutRef = useRef(null);

  const handleSecondarySubjects = (selectedOptions) => {
    setSecondarySubjects(selectedOptions);
  };

  async function getMateriaInfo() {
    const materiaID = localStorage.getItem("matery_id");
    try {
      const materiaInfoApi = await DetailMateriaApi(materiaID);
      console.log(materiaInfoApi.data);
      setMateria(materiaInfoApi.data);
      getAssuntosMateria();
      countQuestion();
    } catch (error) {
      console.log(error);
    }
  }

  async function getAssuntosMateria() {
    const materiaID = localStorage.getItem("matery_id");
    try {
      const subjectsApi = await listSubjectsApi(materiaID);
      console.log(subjectsApi.data);
      setSubjects(subjectsApi.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMateriaInfo();
    getAssuntosMateria();
  }, []);

  const handleAddAnswer = async () => {
    if (!selectedQuestion) return;

    // Verificar se já existe uma resposta correta
    const hasCorrectAnswer = selectedQuestion.answers.some(
      (answer) => answer.answer_verify === 1
    );

    if (isCorrect && hasCorrectAnswer) {
      // Exibir mensagem de erro ao usuário
      alert("Já existe uma resposta correta para essa questão!");
      return;
    }

    const newAnswerObj = {
      question_id: selectedQuestion.id,
      answer: newAnswer,
      is_correct: isCorrect ? 1 : 0,
    };

    // Chamar a função para adicionar a resposta via API ou atualizar o estado
    try {
      const answerInfoApi = await createRespostaQuestaoApi(newAnswerObj);
      console.log(answerInfoApi);

      if (answerInfoApi.status === 200) {
        toast({
          title: "Resposta adicionada com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
      // Exibir mensagem de erro ao usuário, se necessário
    }

    setNewAnswer("");
    setIsCorrect(false);
    setSelectedQuestion(null);
    onAnswerModalClose();
    getMateriaInfo();
  };

  const deleteResposta = async (id) => {
    try {
      const deleteResposta = await deleteRespostaQuestoesApi(id);
      console.log(deleteResposta.data);
      if (deleteResposta.status === 200) {
        toast({
          title: "Resposta deletada com Sucesso",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getMateriaInfo();
  };

  const handleChangeDifficulty = (value) => {
    setDifficulty(value);
  };

  const handleUpdateDifficulty = (value) => {
    setUpdateDifficulty(value);
  };

  const handleSubjectQuestion = (value) => {
    setSubjectQuestion(value);
  };
  const validateAnswers = () => {
    const correctAnswers = answers.filter((answer) => answer.answer_verify);
    if (correctAnswers.length !== 1) {
      alert("Apenas uma resposta correta pode ser selecionada.");
      return false;
    }
    return true;
  };
  const handleAddQuestion = async () => {
    if (!validateAnswers()) {
      return;
    }
    const materiaID = localStorage.getItem("matery_id");
    const data = {
      matery_id: materiaID,
      difficulty: difficulty,
      question: newQuestion,
      subject_id: subjectQuestion,
      materies: secondarySubjects.map((subject) => subject.value),
      answers: answers,
    };
    try {
      const createQuestion = await createQuestaoApi(data);
      console.log(createQuestion.data);
      if (createQuestion.status === 200) {
        toast({
          title: "Questão adicionada com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    // Reset the newQuestion state after submitting
    setNewQuestion("");
    setIsQuestionBoxOpen(false);
    getMateriaInfo();
    handleSubjectQuestion(null);
    setSelectedSecondarySubjects([]);
    setSecondarySubjects([]);
    setDifficulty("");
    setSubjectQuestion("");
    setAnswers([{ answer: "", answer_verify: false }]);
  };

  const handleAddSubject = async () => {
    const materiaID = localStorage.getItem("matery_id");
    const data = {
      matery_id: materiaID,
      subject: subject,
    };
    try {
      const response = await createSubjectApi(data);
      console.log(response.data);
      if (response.status === 200) {
        toast({
          title: "Assunto adicionada com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    // Reset the newQuestion state after submitting
    setSubject("");
    setIsSubjectBoxOpen(false);
    getAssuntosMateria();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChangeNewQuestion = (value) => {
    setNewQuestion(value);
    // Atraso de 800 milissegundos (ajuste conforme necessário)
  };

  const handleChangeNewAnswer = (value) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setNewAnswer(value);
    }, 800); // Atraso de 800 milissegundos (ajuste conforme necessário)
  };
  // Função para lidar com a alteração no JoditEditor
  const handleChange = (value) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setEditedQuestion(value);
    }, 800); // Atraso de 800 milissegundos (ajuste conforme necessário)
  };

  const handleUpdateQuestion = async () => {
    const data = {
      question: editedQuestion,
      subject_id: updateSubject,
      secondary_subjects: selectedSecondarySubjects,
      difficulty: updateDifficulty,
    };

    try {
      const updateQuestionResponse = await updateQuestionApi(questionID, data);
      console.log(updateQuestionResponse);

      if (updateQuestionResponse.status === 200) {
        toast({
          title: "Questão atualizada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Algo deu errado",
        description: "Não foi possível atualizar a questão. Tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Erro ao atualizar questão:", error);
    }

    await getMateriaInfo();
    closeModal();
  };

  const handleUpdateAnswer = async () => {
    const data = {
      answer: answerUpdate,
      answer_verify: answerVerifyUpdate,
    };

    try {
      const updateAnswerResponse = await updateAnswerApi(editingAnswerId, data);

      if (updateAnswerResponse.status === 200) {
        toast({
          title: "Resposta atualizada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Algo deu errado",
        description: "Não foi possível atualizar a resposta. Tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Erro ao atualizar questão:", error);
    }

    await getMateriaInfo();
    closeModal();
  };
  const deleteQuestion = async (id) => {
    try {
      const deleteQuestion = await deleteQuestionApi(id);
      console.log(deleteQuestion.data);
      if (deleteQuestion.status === 200) {
        toast({
          title: "Questão deletada com Sucesso",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getMateriaInfo();
  };

  const deleteSubject = async (id) => {
    try {
      const deleteSubject = await deleteSubjectApi(id);
      console.log(deleteSubject.data);
      if (deleteSubject.status === 200) {
        toast({
          title: "Assunto deletada com Sucesso",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getAssuntosMateria();
  };

  const handleEditClick = (answer) => {
    setEditingAnswerId(answer.id);
    setAnswerUpdate(answer.answer);
    setAnswerVerifyUpdate(answer.answer_verify);
  };

  const handleConfirmEdit = () => {
    handleUpdateAnswer();
    setIsAlertOpen(false); // Fechar o alerta
    setEditingAnswerId(null); // Finalizar edição
  };

  const handleCancelEdit = () => {
    setEditingAnswerId(null); // Cancelar edição
    setAnswerUpdate(""); // Resetar os valores
    setAnswerVerifyUpdate(0); // Resetar verificação
  };

  const handleSaveSubject = async () => {
    const data = {
      subject: newSubjectText,
    };

    try {
      await updateSubjectApi(subjectToEdit.id, data);

      toast({
        title: "Assunto atualizado com Sucesso",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await getMateriaInfo();
      closeEditModal();
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.error("Erro ao atualizar o assunto:", error);
    }
  };

  async function countQuestion() {
    const materiaID = localStorage.getItem("matery_id");
    try {
      const subjectsApi = await getQuestionCountApi(materiaID);
      setQuestionCount(subjectsApi.data[0]);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Navbar />
      {materia && (
        <Box
          p={8}
          ml={{ base: 0, md: 60 }}
          bgColor={"#f0f8ff"}
          minH={"100vh"}
          mt={"-35px"}
        >
          <Flex justifyContent="space-between">
            <Box>
              <Heading as="h1" size="xl" mb={4}>
                {materia.matery?.matery}
              </Heading>
              <Text fontSize="lg" fontWeight="bold">
                Total de Questões: {questionCount}
              </Text>
            </Box>

            <Box>
              <Button
                colorScheme="teal"
                mr={3}
                onClick={() => setIsQuestionBoxOpen(true)}
              >
                Adicionar nova questão
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => setIsSubjectBoxOpen(true)}
              >
                Adicionar Assunto
              </Button>
            </Box>
          </Flex>
          {subjects && (
            <Box my={5}>
              <Text>Lista de assuntos da matéria</Text>
              <HStack spacing={4} wrap="wrap">
                {subjects.map((subject) => (
                  <>
                    <Tag
                      key={subject.id}
                      borderRadius="full"
                      variant="solid"
                      colorScheme="green"
                      width="auto"
                      minWidth="80px"
                    >
                      {/* Botão para editar */}
                      <IconButton
                        icon={<EditIcon />}
                        size="xs"
                        colorScheme="white"
                        onClick={() => handleOpenEditModal(subject)}
                        aria-label="Editar assunto"
                      />
                      <TagLabel>{subject.subject}</TagLabel>
                      {roleId === 1 && (
                        <>
                          {/* Botão para deletar */}
                          <TagCloseButton
                            ml="auto"
                            onClick={() => deleteSubject(subject.id)}
                          />
                        </>
                      )}
                    </Tag>
                  </>
                ))}
              </HStack>
            </Box>
          )}

          {/* Modal para edição do assunto */}
          <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Editar Assunto</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <FormLabel>Assunto</FormLabel>
                  <Input
                    value={newSubjectText}
                    onChange={(e) => setNewSubjectText(e.target.value)}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={closeEditModal}>
                  Cancelar
                </Button>
                <Button colorScheme="blue" onClick={handleSaveSubject}>
                  Salvar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {isQuestionBoxOpen && (
            <Box
              borderWidth="1px"
              borderRadius="md"
              p={4}
              bg="white"
              boxShadow="md"
              mb={5}
              display="flex"
              flexDirection="column"
            >
              {/* Seleção de Dificuldade */}
              <Select
                my={5}
                placeholder="Selecione a dificuldade"
                required
                onChange={(e) => handleChangeDifficulty(e.target.value)}
              >
                <option value="1">Iniciante</option>
                <option value="2">Intermediário</option>
                <option value="3">Avançado</option>
              </Select>

              {/* Seleção de Assunto Principal */}
              <Text>Selecione o assunto principal da questão</Text>
              <Select
                mb={5}
                required
                value={subjectQuestion}
                placeholder="Selecionar assunto principal"
                onChange={(e) => handleSubjectQuestion(e.target.value)}
              >
                {subjects &&
                  subjects.map((subject) => (
                    <option key={subject.id} value={subject.id}>
                      {subject.subject}
                    </option>
                  ))}
              </Select>

              {/* Seleção de Assuntos Secundários */}
              <Text>Selecione os assuntos secundários</Text>
              <MultiSelect
                my={5}
                options={subjects.map((subject) => ({
                  label: subject.subject,
                  value: subject.id,
                }))}
                value={secondarySubjects}
                onChange={setSecondarySubjects}
                labelledBy="Selecione os assuntos secundários"
                overrideStrings={{
                  allItemsAreSelected: "Todos os assuntos foram selecionados",
                  selectSomeItems: "Selecione os assuntos",
                  search: "Pesquisar",
                }}
              />

              <Box marginBottom={5}></Box>
              {/* Editor de Questão */}
              <h2>Questão</h2>
              <MeuEditor
                mt={5}
                my={5}
                value={newQuestion ?? " "}
                onChange={(e) => handleChangeNewQuestion(e)}
              />

              {/* Adicionando Respostas */}
              <Text py={5}>Adicione as respostas da questão</Text>
              {answers.map((answer, index) => (
                <Box key={index} mb={3}>
                  <h2>Alternativa</h2>
                  <MeuEditor
                    placeholder={`Resposta ${index + 1}`}
                    mt={5}
                    my={5}
                    value={answer.answer}
                    onChange={(e) => handleAnswerChange(index, "answer", e)}
                  />
                  <Checkbox
                    mt={1}
                    mr={5}
                    isChecked={answer.answer_verify}
                    onChange={(e) =>
                      handleAnswerChange(
                        index,
                        "answer_verify",
                        e.target.checked
                      )
                    }
                  >
                    Esta é a resposta correta
                  </Checkbox>
                  <Button
                    mt={1}
                    colorScheme="red"
                    size="sm"
                    onClick={() => handleRemoveAnswer(index)}
                  >
                    Remover
                  </Button>
                </Box>
              ))}

              <Button
                colorScheme="blue"
                size="sm"
                my={5}
                width="20%"
                onClick={handleAddAnswerQuestion}
              >
                Adicionar Resposta
              </Button>

              <Box display={"flex"}>
                {/* Botões */}
                <Button
                  colorScheme="teal"
                  onClick={() => {
                    if (
                      !difficulty ||
                      !subjectQuestion ||
                      !newQuestion.trim()
                    ) {
                      alert(
                        "Por favor, preencha todos os campos obrigatórios (Dificuldade, Assunto Principal, e Questão)."
                      );
                      return;
                    }

                    // Se todos os campos obrigatórios estiverem preenchidos, chama a função de adicionar a questão
                    handleAddQuestion();
                  }}
                  mt={5}
                  width="30%"
                >
                  Salvar Questão
                </Button>
                <Button
                  ml={2}
                  colorScheme="red"
                  onClick={() => setIsQuestionBoxOpen(false)}
                  mt={5}
                  width="20%"
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          )}

          {isSubjectBoxOpen && (
            <Box
              borderWidth="1px"
              borderRadius="md"
              p={4}
              bg="white"
              boxShadow="md"
              mb={5}
            >
              <FormControl>
                <FormLabel>Digite o Assunto</FormLabel>
                <Input onChange={(e) => setSubject(e.target.value)} />
              </FormControl>
              <Button colorScheme="teal" onClick={handleAddSubject} mt={5}>
                Salvar Assunto
              </Button>
              <Button
                ml={2}
                colorScheme="red"
                onClick={() => setIsSubjectBoxOpen(false)}
                mt={5}
              >
                Cancelar
              </Button>
            </Box>
          )}
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap={4}
          >
            {materia.matery?.questions?.map((question) => (
              <Box
                key={question.id}
                borderWidth="1px"
                borderRadius="md"
                p={4}
                bg="white"
                boxShadow="md"
              >
                <Text fontSize={"18px"} fontWeight={"bold"} py={5}>
                  Dificuldade:
                  {question.difficulty === 1
                    ? " Iniciante"
                    : question.difficulty === 2
                    ? " Intermediário"
                    : question.difficulty === 3
                    ? " Avançado"
                    : ""}
                </Text>
                {question.subject && (
                  <Text fontSize={"16px"} fontWeight={"bold"} py={5}>
                    Assunto principal: {question.subject.subject}
                  </Text>
                )}

                {question.secondary_subjects?.length > 0 && (
                  <Box>
                    <Text fontSize={"16px"} fontWeight={"bold"}>
                      Assuntos Secundários:
                    </Text>
                    <Box display="inline-flex" flexWrap="wrap" gap="10px">
                      {question.secondary_subjects.map((secSubject) => (
                        <Text key={secSubject.id} fontSize="14px">
                          {secSubject.subject}
                        </Text>
                      ))}
                    </Box>
                  </Box>
                )}
                <Box fontSize="md" mb={2} pt={5}>
                  <Text fontSize={"16px"} fontWeight={"bold"}>
                    Descrição da questão:{" "}
                  </Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: isQuestionEditing
                        ? editedQuestion
                        : question.question,
                    }}
                  />
                </Box>
                {question.answers.map((answer, index) => (
                  <Box
                    key={answer.id}
                    p={2}
                    borderBottom={"1px solid #e9e9e9"}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display={"block"} alignItems="center">
                      <Text fontWeight={"bold"}>
                        ({String.fromCharCode(65 + index)})
                      </Text>
                      <Box ml={5}>
                        {editingAnswerId === answer.id ? (
                          <MeuEditor
                            placeholder={`Resposta ${index + 1}`}
                            mt={5}
                            my={5}
                            initialValue={answerUpdate}
                            onChange={(e) => setAnswerUpdate(e)}
                          />
                        ) : (
                          <span
                            ml={5}
                            dangerouslySetInnerHTML={{ __html: answer.answer }}
                          />
                        )}
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center" ml={4}>
                      {editingAnswerId === answer.id ? (
                        <Select
                          value={answerVerifyUpdate}
                          onChange={(e) =>
                            setAnswerVerifyUpdate(Number(e.target.value))
                          }
                          width="120px"
                        >
                          <option value={1}>Correta</option>
                          <option value={0}>Incorreta</option>
                        </Select>
                      ) : (
                        <Text
                          color={
                            answer.answer_verify === 1 ? "green.500" : "red.500"
                          }
                        >
                          {answer.answer_verify === 1
                            ? "Resposta correta"
                            : "Resposta incorreta"}
                        </Text>
                      )}

                      <Box ml={4}>
                        {editingAnswerId === answer.id ? (
                          <>
                            <Button
                              size="sm"
                              mt={3}
                              colorScheme="blue"
                              onClick={() => setIsAlertOpen(true)} // Confirmar edição
                            >
                              Confirmar
                            </Button>
                            <Button
                              size="sm"
                              mt={3}
                              colorScheme="gray"
                              onClick={handleCancelEdit} // Cancelar edição
                              ml={2}
                            >
                              Cancelar
                            </Button>
                          </>
                        ) : (
                          <Button
                            size="sm"
                            mt={3}
                            colorScheme="yellow"
                            onClick={() => handleEditClick(answer)} // Habilitar edição
                          >
                            Editar
                          </Button>
                        )}
                      </Box>

                      <Box ml={2}>
                        <Button
                          size="sm"
                          mt={3}
                          colorScheme="red"
                          onClick={() => deleteResposta(answer.id)} // Excluir resposta
                        >
                          Excluir
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ))}

                {/* Alerta de Confirmação */}
                <AlertDialog
                  isOpen={isAlertOpen}
                  onClose={() => setIsAlertOpen(false)}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Confirmar Edição
                      </AlertDialogHeader>
                      <AlertDialogCloseButton />
                      <AlertDialogBody>
                        Tem certeza de que deseja salvar as alterações nesta
                        resposta?
                      </AlertDialogBody>
                      <AlertDialogFooter>
                        <Button onClick={() => setIsAlertOpen(false)}>
                          Cancelar
                        </Button>
                        <Button
                          colorScheme="blue"
                          onClick={handleConfirmEdit}
                          ml={3}
                        >
                          Confirmar
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>

                <Center>
                  <Button
                    mt={4}
                    colorScheme="teal"
                    onClick={() => {
                      setSelectedQuestion(question);
                      onAnswerModalOpen();
                    }}
                  >
                    Adicionar Resposta
                  </Button>
                  <Button
                    mt={4}
                    ml={5}
                    onClick={() => {
                      openModal(question.id);
                      setEditedQuestion(question.question);
                      setQuestionID(question.id);
                      setUpdateDifficulty(question.difficulty);
                    }}
                  >
                    Editar Questão
                  </Button>
                  <Button
                    mt={4}
                    ml={5}
                    colorScheme="red"
                    onClick={() => deleteQuestion(question.id)}
                  >
                    Excluir Questão
                  </Button>
                </Center>
                <Modal isOpen={isAnswerModalOpen} onClose={onAnswerModalClose}>
                  <ModalOverlay />
                  <ModalContent width={"1000px"} maxW={"90%"}>
                    <ModalHeader>Adicionar Resposta</ModalHeader>
                    <ModalBody>
                      <VStack spacing={4} align="stretch">
                        <Text>Resposta:</Text>
                        <MeuEditor
                          value={newAnswer ?? " "}
                          onChange={(value) => handleChangeNewAnswer(value)}
                        />
                        <Text>Verdadeiro ou Falso:</Text>
                        <Select
                          value={isCorrect ? "true" : "false"}
                          onChange={(e) =>
                            setIsCorrect(e.target.value === "true")
                          }
                        >
                          <option value="true">Verdadeiro</option>
                          <option value="false">Falso</option>
                        </Select>
                      </VStack>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        colorScheme="teal"
                        mr={3}
                        onClick={handleAddAnswer}
                      >
                        Adicionar
                      </Button>
                      <Button onClick={() => onAnswerModalClose()}>
                        Cancelar
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </Box>
            ))}
          </Grid>
        </Box>
      )}

      {/** Modal criar questao */}

      <Modal isOpen={isQuestionModalOpen} onClose={onQuestionModalClose}>
        <ModalOverlay />
        <ModalContent width={"900px"} maxW={"90%"}>
          <ModalHeader>Adicionar Questão</ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Select
                placeholder="Selecione a dificuldade"
                required
                onChange={(e) => handleChangeDifficulty(e.target.value)}
              >
                <option value="1">Iniciante</option>
                <option value="2">Intermediário</option>
                <option value="3">Avançado</option>
              </Select>
              <Text>Questão:</Text>
              <MeuEditor
                value={newQuestion ?? " "}
                onChange={(value) => handleChangeNewQuestion(value)}
                spellCheck={false}
                cursorOffSet={50}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddQuestion}>
              Adicionar
            </Button>
            <Button onClick={() => onQuestionModalClose()}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {subjects && subjects?.length > 0 && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent width={"1000px"} maxW={"90%"}>
            <ModalHeader>Editar Questão</ModalHeader>
            <ModalBody>
              {/* Seleção de Dificuldade */}
              <Select
                my={5}
                placeholder="Selecione a dificuldade"
                required
                value={updateDifficulty}
                onChange={(e) => handleUpdateDifficulty(e.target.value)}
              >
                <option value="1">Iniciante</option>
                <option value="2">Intermediário</option>
                <option value="3">Avançado</option>
              </Select>
              <Text>Selecione o assunto principal da questão</Text>
              <Select
                mb={5}
                required
                placeholder="Selecionar assunto principal"
                value={updateSubject}
                onChange={(e) => setUpdateSubject(e.target.value)}
              >
                {subjects.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject.subject}
                  </option>
                ))}
              </Select>

              <Text>Assuntos Secundários Selecionados:</Text>

              <Box display="inline-flex" flexWrap="wrap" gap="10px">
                {checkQuestion?.secondary_subjects?.map((subject) => (
                  <Text fontSize="14px" color={"black"} key={subject.id}>
                    {subject?.subject}
                  </Text>
                ))}
              </Box>
              <Box my={5}></Box>
              <Text>Selecione os assuntos secundários da questão</Text>

              <MultiSelect
                options={subjects.map((subject) => ({
                  label: subject.subject,
                  value: subject.id,
                }))}
                value={selectedSecondarySubjects} // Aqui ele estará vazio
                onChange={setSelectedSecondarySubjects} // Atualiza o valor ao selecionar/desmarcar
                labelledBy="Selecione os assuntos secundários"
                overrideStrings={{
                  allItemsAreSelected: "Todos os assuntos foram selecionados",
                  selectSomeItems: "Selecione os assuntos",
                  search: "Pesquisar",
                }}
              />

              <Box my={5}></Box>
              <MeuEditor
                initialValue={editedQuestion}
                value={editedQuestion}
                onChange={handleChange}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="teal"
                onClick={() =>
                  handleUpdateQuestion(
                    subjectQuestionCheck,
                    selectedSecondarySubjects
                  )
                }
              >
                Atualizar
              </Button>
              <Button onClick={closeModal}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default MateriaPage;
